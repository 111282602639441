import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SideBar from '../../components/VSCode/SideBar';
import TabsWrapper from '../../components/VSCode/TabsWrapper';
import { bioIndex } from '../../constants/pageSourceCodes';
import { useMenu } from '../../hooks/useMenu';
import Routes from '../../Routes';
import { getPageCode } from '../../Utils/getPageCode';
import {
  Container,
  SideBarWrapper,
  Content,
  View,
} from './style';

const VSCode = () => {
  const { isMenuOpened, isCodeView, selected } = useMenu();
  const [code, setCode] = useState(bioIndex);

  useEffect(() => {
    setCode(getPageCode(selected));
  }, [selected]);
  return (
    <Container>
      <SideBarWrapper>
        <SideBar />
      </SideBarWrapper>
      <Content>
        <TabsWrapper />
        <View isMenuOpened={isMenuOpened}>
          {
            isCodeView
              ? (
                <SyntaxHighlighter
                  language="typescript"
                  showLineNumbers
                  customStyle={{ background: '#191725', fontSize: 18 }}
                  style={tomorrowNight}
                >
                  {code}
                </SyntaxHighlighter>
              )
              : <Routes />
          }
          <Outlet />
        </View>
      </Content>
    </Container>
  );
};

export default VSCode;
